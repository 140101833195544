import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TableContainer,
  Table,
} from "@material-ui/core";

//import action
import { walletTransfer } from "../../actions/admin";
//import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const intialFormvalue = {
  amount: "",
  toAddress: "",
  otpTextBox: false,
  otp: "",
  password: "",
};

const WithdrawCoinModal = (props) => {
  const classes = useStyles();

  const { handleModal, openModal, data } = props;

  // state
  const [modalStyle] = useState(getModalStyle);
  const [formVlaue, setFormValue] = useState(intialFormvalue);
  const [validate, setValidate] = useState({});
  const [lodaer, setlodaer] = useState(false);
  const { amount, toAddress, otpTextBox, otp, password } = formVlaue;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formVlaue, ...{ [name]: value } };
    setValidate({});
    setlodaer(false);
    setFormValue(formData);
  };

  const handleSubmit = async (e) => {
    try {
      let reqData = {
        toAddress,
        amount,
        password,
        currencyId: data && data.currencyId && data.currencyId._id,
        otpTextBox,
        otp,
      };
      setlodaer(true);
      const { status, loading, message, error } = await walletTransfer(reqData);
      if (status == true) {
        setlodaer(false);
        setFormValue(intialFormvalue);
        toastAlert("success", message, "walletTransfer");
        setValidate({});
        handleModal();
      } else if (status == "OTP") {
        setlodaer(false);
        let formData = { ...formVlaue, ...{ otpTextBox: true } };
        setFormValue(formData);
        setValidate({});
        toastAlert("success", message, "walletTransfer");
      } else {
        if (!isEmpty(error)) {
          setValidate(error);
          return;
        }
        toastAlert("error", message, "walletTransfer");
      }
    } catch (err) {
      console.log("...errr", err);
    }
  };

  return (
    <div>
      <Modal
        className="primary_modal"
        open={openModal}
        onClose={handleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper + " primary_modal_body"}>
          <h2>Withdraw</h2>
          <TableContainer className="mt-3">
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell align="left">{"To Address :"}</TableCell>
                  <TableCell align="left">
                    {" "}
                    <input
                      type="text"
                      value={toAddress}
                      name={"toAddress"}
                      onChange={handleChange}
                      className="tableSearch"
                    />
                    {validate.toAddress && (
                      <p className="error-message">{validate.toAddress}</p>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">{"Amount :"}</TableCell>
                  <TableCell align="left">
                    {" "}
                    <input
                      type="text"
                      name={"amount"}
                      value={amount}
                      onChange={handleChange}
                      className="tableSearch"
                    />
                    {validate.amount && (
                      <p className="error-message">{validate.amount}</p>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">{"Password :"}</TableCell>
                  <TableCell align="left">
                    {" "}
                    <input
                      type="password"
                      name={"password"}
                      value={password}
                      onChange={handleChange}
                      className="tableSearch"
                    />
                    {validate.password && (
                      <p className="error-message">{validate.password}</p>
                    )}
                  </TableCell>
                </TableRow>
                {otpTextBox && (
                  <TableRow>
                    <TableCell align="left">{"Otp :"}</TableCell>
                    <TableCell align="left">
                      {" "}
                      <input
                        type="text"
                        name={"otp"}
                        value={otp}
                        onChange={handleChange}
                        className="tableSearch"
                      />
                      {validate.otp && (
                        <p className="error-message">{validate.otp}</p>
                      )}
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell align="left">{"Currency :"}</TableCell>
                  <TableCell align="left">{data.currencySymbol}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">{"Balance :"}</TableCell>
                  <TableCell align="left">{data.balance}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell>
                    <Button
                      className="mr-2"
                      variant="contained"
                      // disabled={lodaer}
                      onClick={() => {
                        // let formData={...formVlaue,...{amount:"",}}
                        setFormValue(intialFormvalue);
                        setValidate({});
                        handleModal();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      disabled={lodaer}
                      onClick={() => handleSubmit()}
                    >
                      Withdraw
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
};

// WithdrawModal.propTypes = {
//     data: PropTypes.shape({
//         email: PropTypes.string.isRequired,
//         coin: PropTypes.string.isRequired,
//         toaddress: PropTypes.string.isRequired,
//         amount: PropTypes.number.isRequired,
//         commissionFee: PropTypes.number.isRequired,
//     }),
// };

// WithdrawCoinModal.defaultProps = {
//     data: {
//         email: '',
//         currencySymbol: '',
//         toaddress: '',
//         amount: 0,
//         commissionFee: 0,
//     }
// };

export default WithdrawCoinModal;
