import React, { useState } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    TableBody,
    TableRow,
    TableCell,
    Button
} from '@material-ui/core';

// import action
import { acceptWithdraw, rejectDeposit, acceptDeposit} from './../../actions/wallet';

// import lib
import { toastAlert } from '../../lib/toastAlert'
import config from '../../lib/config';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const WithdrawModal = (props) => {
    const classes = useStyles();

    const { handleModal, openModal, data, fetchDepositRequest } = props;

    // state
    const [modalStyle] = useState(getModalStyle);
    const [isApprovedLoading, setApprovedLoading] = useState(false);

    // function
    // const handeRejectWithdraw = async (transactionId) => {
    //     setApprovedLoading(true)
    //     try {
    //         let reqData = {
    //             transactionId
    //         }
    //         const { status, loading, error, result } = await rejectFiatWithdraw(reqData);
    //         setApprovedLoading(loading)
    //         handleModal({})
    //         fetchWithdrawRequest()
    //         if (status == 'success') {
    //             toastAlert('success', result.messages, 'withdrawModal')
    //         } else {
    //             toastAlert('error', error.messages, 'withdrawModal')
    //         }
    //     } catch (err) {
    //         setApprovedLoading(false)
    //     }
    // }

    const handeRejectDeposit=async (transactionId)=>{
    setApprovedLoading(true)
         try{
             let reqData = {
                transactionId
            }
            const { status, message } = await rejectDeposit(reqData);
            setApprovedLoading(false)
            handleModal({})
            fetchDepositRequest()
            if (status) {
                toastAlert('success', message, 'rejectDeposit')
            } 
         }catch (err){

         }
    }
    // const handeRejectWithdraw = async (transactionId) => {
    //     setApprovedLoading(true)
    //      try{
    //          let reqData = {
    //             transactionId
    //         }
    //         const { status, message } = await rejectWithdraw(reqData);
    //         setApprovedLoading(false)
    //         handleModal({})
    //         fetchWithdrawRequest()
    //         if (status) {
    //             toastAlert('success', message, 'rejectWithdraw')
    //         } else {
    //             toastAlert('error',message, 'rejectWithdraw')
    //         }
    //      }catch (err){

    //      }
    // }

    const handeAcceptWithdraw = async (transactionId) => {
        setApprovedLoading(true)
        try {
            let reqData = {
                transactionId
            }
            const { status, message } = await acceptDeposit(reqData);
            
            if (status) {
                setApprovedLoading(false)
                handleModal({})
                fetchDepositRequest()
                toastAlert('success', message, 'acceptDeposit')
             } 
            //else {
            //     setApprovedLoading(false)
            //     handleModal({})
            //     fetchDepositRequest()
            //     toastAlert('error',message, 'acceptDeposit')
            // }
        } catch (err) {
            setApprovedLoading(false)
        }
    }

    let buttonDisable = false;
    if (isApprovedLoading) {
        buttonDisable = true
    }

    return (
        <div>
            <Modal
                className="primary_modal"
                open={openModal}
                onClose={handleModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div  className={classes.paper + " primary_modal_body"}>
                    <h2>Withdraw</h2>
                    <TableBody>
                        <TableRow key={'Email'}>
                            <TableCell align="left">{"Email :"}</TableCell>
                            <TableCell align="left">{data.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">{"UserId :"}</TableCell>
                            <TableCell align="left">{data.userAssetId}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">{"Currency Symbol :"}</TableCell>
                            <TableCell align="left">{data.currencySymbol}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">{"Actual Amount :"}</TableCell>
                            <TableCell align="left">{data.actualAmount}</TableCell>
                        </TableRow>

                      

                        <TableRow>
                            <TableCell align="left">{"Amount :"}</TableCell>
                            <TableCell align="left">{data.amount}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">{"Document :"}</TableCell>
                            <TableCell align="left">
                                {
                                   ( data.document!=undefined && data.document!="")
                                   ?
                                   <a href={config.API_URL+'/images/depositDocs/'+data.document} target="_blank">View Document</a>
                                :""
                                }
                          
                            </TableCell>
                        </TableRow>

                        <TableRow>
                        <TableCell align="center" colSpan="2">
                            <Button
                                className="mr-2"
                                variant="contained"
                                disabled={buttonDisable}
                                onClick={() => handeRejectDeposit(data._id)}
                            >
                                Reject
                            </Button>

                            <Button
                            className="mr-2"
                                variant="contained"
                                disabled={buttonDisable}
                                onClick={() => handeAcceptWithdraw(data._id)}
                            >
                                Approved
                            </Button>
                           
                            <Button
                            className="mr-2"
                                variant="contained"
                                disabled={buttonDisable}
                                onClick={() =>handleModal()}
                            >
                              Close
                            </Button>
                            
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </div>
            </Modal>
        </div >
    );
}

WithdrawModal.propTypes = {
    data: PropTypes.shape({
        email: PropTypes.string.isRequired,
        currencySymbol: PropTypes.string.isRequired,
        toaddress: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        commissionFee: PropTypes.number.isRequired,
    }),
};

WithdrawModal.defaultProps = {
    data: {
        email: '',
        currencySymbol: '',
        toaddress: '',
        amount: 0,
        commissionFee: 0,
    }
};

export default WithdrawModal
