import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import PatternLock from "react-pattern-lock";

import "react-phone-input-2/lib/style.css";

import isEmpty from "../../lib/isEmpty";

import { updatePassword } from "../../actions/users";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [pattern, setPattern] = useState("");
  const [changePattern, setchangePattern] = useState(false);
  const [newPattern, setnewPattern] = useState("");
  const { oldPassword, newPassword, confirmPassword } = formData;
  // function
  const onChange = (e) => {
    e.preventDefault();
    setValidateError({});
    const { id, value } = e.target;
    let formValue = { ...formData, ...{ [id]: value } };
    setFormData(formValue);
  };
  const patternChange = (pattern) => {
    delete validateError["pattern"];
    setPattern(pattern);
  };
  const newPatternChange = (pattern) => {
    delete validateError["newPattern"];
    setnewPattern(pattern);
  };

  const passwordChange = async (e) => {
    e.preventDefault();
    let reqData = {
      oldPassword,
      newPassword,
      confirmPassword,
      pattern: pattern.toString(),
      changePattern,
      newPattern: newPattern.toString(),
    };

    let { error, message } = await updatePassword(reqData);

    if (isEmpty(error)) {
      setValidateError({});
      setFormData(initialFormValue);
      toast.success(message, toasterOption);
      patternChange([]);
      newPatternChange([]);
      history.push("/dashboard");
    } else {
      setValidateError(error);
    }
  };

  return (
    // formData.map(formData => ()
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Change Password and Pattern
                </h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {!changePattern && (
                    <>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Old Password"
                          onChange={onChange}
                          id="oldPassword"
                          type="password"
                          // name="facebook"
                          value={oldPassword}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {validateError.oldPassword && (
                          <span className="error-message">
                            {validateError.oldPassword}
                          </span>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          id="filled-disabled"
                          variant="filled"
                          labelText="New Password"
                          className="classes.disabled"
                          type="password"
                          value={newPassword}
                          onChange={onChange}
                          // id="email"
                          id="newPassword"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {validateError.newPassword && (
                          <span className="error-message">
                            {validateError.newPassword}
                          </span>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          id="filled-disabled"
                          variant="filled"
                          labelText="Confirm Password"
                          type="password"
                          className="classes.disabled"
                          value={confirmPassword}
                          onChange={onChange}
                          // id="email"
                          id="confirmPassword"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {validateError.confirmPassword && (
                          <span className="error-message">
                            {validateError.confirmPassword}
                          </span>
                        )}{" "}
                      </GridItem>
                    </>
                  )}

                  {changePattern && (
                    <GridItem xs={12} sm={12} md={6}>
                      <label>Old Pattern</label>
                      <PatternLock
                        style={{ backgroundColor: "red" }}
                        width={300}
                        pointSize={15}
                        size={3}
                        path={pattern}
                        onChange={patternChange}
                        onFinish={() => {
                          // console.log("patternn", this.state.path.join(""))
                          // check if the pattern is correct
                        }}
                      />
                      <span className="error-message">
                        {validateError.pattern}
                      </span>
                    </GridItem>
                  )}
                  {changePattern && (
                    <GridItem xs={12} sm={12} md={6}>
                      <label>New Pattern</label>
                      <PatternLock
                        style={{ backgroundColor: "red" }}
                        width={300}
                        pointSize={15}
                        size={3}
                        path={newPattern}
                        onChange={newPatternChange}
                        onFinish={() => {
                          // console.log("patternn", this.state.path.join(""))
                          // check if the pattern is correct
                        }}
                      />
                      <span className="error-message">
                        {validateError.newPattern}
                      </span>
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="button" onClick={passwordChange}>
                  {!changePattern ? "upadate Password" : "upadate Pattern"}
                </Button>
                {changePattern && (
                  <Button
                    color="primary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      patternChange([]);
                      newPatternChange([]);
                    }}
                  >
                    Reset Pattern
                  </Button>
                )}

                <Button
                  color="primary"
                  type="button"
                  onClick={() =>
                    !changePattern
                      ? setchangePattern(true)
                      : setchangePattern(false)
                  }
                >
                  {changePattern ? "Change Password" : "Change Pattern"}
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
