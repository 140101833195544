// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp
} from "@material-ui/icons";

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "forget",
    path: "/forget",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "change-password",
    path: "/changepassword/:authToken",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "security",
    path: "/securityType",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },

  /* Dashboard */
  // {
  //   id: "dashboard",
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   isSideMenu: true,
  //   isEdit: false,
  //   isRestricted: 'single'
  // },
  {
    id: "admin-wallet-list",
    path: "/admin-wallet-list",
    name: "Wallet List",
    icon: History,
    isSideMenu: true,
    isEdit: false,
    isRestricted: 'single'
  },
  {
    id: "wallet-transfer-list",
    path: "/wallet-transfer-list",
    name: "Wallet Transfer Details",
    icon: History,
    isSideMenu: true,
    isEdit: false,
    isRestricted: 'single'
  },

  /*Admin  */
  // {
  //   id: "admin",
  //   path: "/list",
  //   name: "Admin Management",
  //   icon: List,
  //   isSideMenu: true,
  //   isEdit: true,
  //   isRestricted: 'multi',
  //   child: [
  //     {
  //       id: 'add',
  //       path: "/add",
  //     },
  //     {
  //       id: 'edit',
  //       path: "/edit/:userId",
  //     }
  //   ]
  // },

  /* User */
  // {
  //   path: "#",
  //   id: "user",
  //   name: "User Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "User Lists",
  //       path: "/userList",
  //       icon: Person,
  //     },
  //     {
  //       name: "User Balance",
  //       path: "/userBlance",
  //       icon: History,
  //     },
  //     {
  //       name: "Login History",
  //       path: "/loginhistory",
  //       icon: History,
  //     },
  //   ]
  // },


  // {
  //   path: "/idproof",
  //   id: "proof",
  //   path: "#",
  //   name: "Doc Verfication",
  //   icon: Person,
  //   type: "multi",
  //   isSideMenu: true,
  //   child: [
  //     {
  //       path: "/idproof",
  //       name: "ID Proof",
  //       icon: Person,
  //     },
  //     {
  //       path: "/selfieproof",
  //       name: "Selfie Proof",
  //       icon: Person,
  //     },
  //     // {
  //     //   path: "/addressproof",
  //     //   name: "Address Proof",
  //     //   icon: Person,
  //     // },
  //     {
  //       path: "/bankproof",
  //       name: "BanK Proof",
  //       icon: Person,
  //     },

  //   ]
  // },
  // {
  //   path: "/currency",
  //   name: "Currency",
  //   icon: Money,
  //   isSideMenu: true,
  // },
  // // {
  // //   path: "/media-partner",
  // //   name: "Media Partner",
  // //   icon: Money,
  // //   isSideMenu: true,
  // // },
  // {
  //   path: "/notification",
  //   name: "Notification",
  //   icon: Money,
  //   isSideMenu: true,
  // },
  // {
  //   path: "/tradePairs",
  //   name: "Trade Pairs",
  //   icon: LibraryBooks,
  //   isSideMenu: true,
  // },
  // {
  //   path: "/trade-history",
  //   name: "Trade",
  //   icon: History,
  //   isSideMenu: true,
  // },
  // {
  //   path: "/order-history",
  //   name: "Order History",
  //   icon: History,
  //   isSideMenu: true,
  // },
  // // {
  // //   path: "/report",
  // //   id: "report",
  // //   path: "#",
  // //   name: "Report",
  // //   icon: Equalizer,
  // //   type: "multi",
  // //   isSideMenu: true,
  // //   child: [
  // //     {
  // //       path: "/trade-history",
  // //       name: "Trade",
  // //       icon: History,
  // //     },
  // //     {
  // //       path: "/withdraw",
  // //       name: "Withdraw",
  // //       icon: AccountBalanceWallet,
  // //     },
  // //     {
  // //       path: "/deposit",
  // //       name: "Deposit",
  // //       icon: AccountBalanceWallet,
  // //     },
  // //     {
  // //       path: "/fund-transfer-history",
  // //       name: "Fund Transfer",
  // //       icon: AccountBalanceWallet,
  // //     }
  // //   ]
  // // },
  // {
  //   path: "/passBookHistoy",
  //   name: "PassBook History",
  //   icon: Money,
  //   isSideMenu: true,
  // },
  // {
  //   path: "/wallet",
  //   id: "wallet",
  //   path: "#",
  //   name: "Wallet",
  //   icon: AccountBalance,
  //   type: "multi",
  //   isSideMenu: true,
  //   child: [
  //     // {
  //     //   path: "/withdraw-request/coin",
  //     //   name: "Coin Withdraw Request",
  //     //   icon: AccountBalanceWallet,
  //     // },
  //     {
  //       path: "/withdraw-request/coin",
  //       name: "Withdraw Request",
  //       icon: ExitToApp,
  //     },
  //     {
  //       path: "/deposit-request/coin",
  //       name: "Deposit Request",
  //       icon: ExitToApp,
  //     },
  //     {
  //       path: "/fiat-withdraw",
  //       name: "Fiat Withdraw  Request",
  //       icon: ExitToApp,
  //     },
  //     {
  //       path: "/fiat-deposit",
  //       name: "Fiat Deposit Request",
  //       icon: ExitToApp,
  //     },
  //     {
  //       path: "/transfer-history",
  //       name: "Transfer History",
  //       icon: List,
  //     },
  //   ]
  // },
  // {
  //   path: "/conatctList",
  //   name: "Contact List",
  //   icon: Help,
  //   isSideMenu: true,
  // },


  // {
  //   path: "/securityType",
  //   id: "setting",
  //   path: "#",
  //   name: "Settings",
  //   icon: Settings,
  //   type: "multi",
  //   isSideMenu: true,
  //   child: [
  //     {
  //       path: "/securityType",
  //       name: "Security Type",
  //       isRestricted: 'common',
  //       icon: Person,
  //     }
  //   ]
  // },

  // {
  //   path: "/",
  //   id: "referral",
  //   path: "#",
  //   name: "Referral",
  //   icon: Settings,
  //   type: "multi",
  //   isSideMenu: true,
  //   child: [
  //     {
  //       path: "/referral/settings",
  //       name: "Referral Setting",
  //       isRestricted: 'common',
  //       icon: Person,
  //     },
  //     {
  //       path: "/referral/history",
  //       name: "Referral History",
  //       isRestricted: 'common',
  //       icon: Person,
  //     },
  //   ]
  // },


  // {
  //   path: "/emailTemplate",
  //   name: "Email Template",
  //   icon: Email,
  //   isSideMenu: true,
  // },
  // {
  //   path: "/support/ticket",
  //   name: "Support Ticket",
  //   icon: Help,
  //   isSideMenu: true,
  // },
  // // {
  // //   path: "/support",
  // //   id: "support",
  // //   path: "#",
  // //   name: "Support",
  // //   icon: Help,
  // //   type: "multi",
  // //   isSideMenu: true,
  // //   child: [
  // //     {
  // //       path: "/support/category",
  // //       name: "Support Category",
  // //       icon: Help,
  // //     },
  // //     {
  // //       path: "/support/ticket",
  // //       name: "Support Ticket",
  // //       icon: Help,
  // //     },
  // //   ]
  // // },
  // {
  //   path: "/cmsList",
  //   name: "cms",
  //   icon: List,
  //   isSideMenu: true,
  // },
  // {
  //   path: "/faq",
  //   name: "Faq",
  //   icon: LiveHelp,
  //   isSideMenu: true,
  // }
];

export default dashboardRoutes;
