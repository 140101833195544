import React, { useEffect, useState,forwardRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import config from "../../lib/config";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import ReCAPTCHA from "react-google-recaptcha";
import queryString from 'query-string';

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory ,useLocation} from "react-router-dom";
import { useDispatch } from "react-redux";
import PatternLock from "react-pattern-lock";

// import action
import { setCurrentUser, login } from "./../../actions/users";

// import lib
import validation from "./Validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textDanger: {
    color: "#f32828",
  },
}));

const initialFormValue = {
  email: "",
  password: "",
  tfaCode: "",
  otpTextBox: false,
  otp: "",
  recaptcha:""
};
const useQuery = () => {
  return queryString.parse(useLocation().search);
}

const recaptchaRef = React.createRef();
  const grecaptchaObject = window.grecaptcha;

const  SignIn=forwardRef(()=> {
  
  const classes = useStyles();
  const history = useHistory();
  let query = useQuery();

  const dispatch = useDispatch();
  const [url, seturl] = useState("");
  // const [recaptcha,setrecaptcha]=useState('')
  const [textBoxHidden, setTextBoxHidden] = useState(false);
  const [pattern, setPattern] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});

  const { email, password, tfaCode, otpTextBox, otp ,recaptcha} = formValue;
  // function

  useEffect(() => {
    const urls = config.ADMIN_URL;
    seturl(urls);
  }, []);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let reqData = {
      email,
      password,
      tfaCode,
      otp,
      otpTextBox,
      recaptcha:recaptcha,
      pattern: pattern.toString(),
    };
    let { error, result, success, tfaStatus, message } = await login(
      reqData,
      dispatch
    );
      console.log("...err",error,message)
    if (isEmpty(error)) {
      if (success == "OTP") {
        let formData = { ...formValue, ...{ otpTextBox: true } };
        setFormValue(formData);
        setValidateError({});
        toastAlert("success", message, "login");
      } else {
        setFormValue(initialFormValue);
        toastAlert("success", message, "login");
        history.push("/admin-wallet-list");
        dispatch(setCurrentUser(result));
      }
    } else {
      if (tfaStatus) {
        setTextBoxHidden(true);
      }
      toastAlert("error",error.message, "login");
      setValidateError(error);
    }
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const patternChange = (pattern) => {
    // let validate={...validateError,...{pattern:""}}
    // setValidateError(validate)
    delete validateError["pattern"];
    setPattern(pattern);
  };
  const handleRecaptcha = (value) => {
    let formData = { ...formValue, ...{ 'recaptcha': value } }
    setFormValue(formData)
    setValidateError({});

}
  useEffect(() => {
    if (query && query.ref && !isEmpty(query.ref)) {
      let formData = { ...formValue, ...{ 'referralCode': query.ref } }

      setFormValue(formData)
      setValidateError(validation(formData))
  } else {
      setValidateError(validation(formValue))
  }
    // setValidateError(validation(formValue));
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="email"
            autoFocus
          />
          {toched.email && validateError.email && (
            <p className="error-message">{validateError.email}</p>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {toched.password && validateError.password && (
            <p className="error-message">{validateError.password}</p>
          )}
          <PatternLock
            style={{ backgroundColor: "red" }}
            width={300}
            pointSize={15}
            size={3}
            path={pattern}
            onChange={patternChange}
            onFinish={() => {
              // console.log("patternn", this.state.path.join(""))
              // check if the pattern is correct
            }}
          />
          <span className="error-message">{validateError.pattern}</span>
          {otpTextBox ? (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="otp"
              onChange={handleChange}
              // onBlur={handleBlur}
              label="Otp"
              type="text"
              id="otp"
              autoComplete="current-password"
            />
          ) : (
            ""
          )}
          {<p className="error-message">{validateError.otp}</p>}
          {textBoxHidden ? (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="tfaCode"
              onChange={handleChange}
              // onBlur={handleBlur}
              label="2FA Code"
              type="text"
              id="tfaCode"
              autoComplete="current-password"
            />
          ) : (
            ""
          )}
          {<p className="error-message">{validateError.tfaCode}</p>}
              <ReCAPTCHA
                    ref={recaptchaRef}
                    grecaptcha={grecaptchaObject}
                    sitekey={config.Recaptchakey}
                    onChange={handleRecaptcha}
                />
                  {<p className="error-message">{validateError.recaptcha}</p>}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
              e.preventDefault();
              patternChange([]);
            }}
          >
            Reset the input pattern field{" "}
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!isEmpty(validateError)}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              {/* <Link href={url + "/forget"} variant="body2">
                Forgot password?
              </Link> */}
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
})

export default SignIn