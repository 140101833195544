// import axios
import axios from "axios";

// import lib
import config from "../lib/config";
import { getAuthToken } from "../lib/localStorage";

axios.defaults.headers.common["Authorization"] = getAuthToken();

export const getAllAdmin = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/admin`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deleteAdmin = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/deleteAdmin/${id}`,
    });
    return {
      status: respData.data.status,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addNewAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminapi/admin`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const editAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/admin`,
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getSingleAdmin = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/singleAdmin/${id}`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getContactList = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/contactPage`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const replyContact = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/contactPage`,
      data: data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAdminWalletLsit = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminWalletApi/getAdminWallet`,
      params: data,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const walletTransfer = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminWalletApi/send-wallet-transfer`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getWalletTransfer = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminWalletApi/get-transfer-list`,
      params: data,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const changeWalletAddress = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminWalletApi/changeWalletAddress`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
