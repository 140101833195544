import axios from 'axios';

// import action
import { decodeJwt } from './jsonWebToken';

// import lib
import config from '../lib/config';
import setAuthToken from '../lib/setAuthToken';
import {toastAlert} from '../lib/toastAlert'
import {
    SET_CURRENT_USER
} from '../constant';
import { console_log } from 'helper/default';


// import constant
// import {
//     SET_CURRENT_USER
// } from '../constant';


export const login = async (data, dispatch) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminWalletApi/login`,
            data
        });
        
        if(respData.data.success==true){
            localStorage.setItem('admin_wallet_token', respData.data.token);
            setAuthToken(respData.data.token)
            decodeJwt(respData.data.token, dispatch)
    
        }
      
        
        return {
            loading: false,
            result: respData.data.result,
            success:respData.data.success,
            message:respData.data.message,
            error: respData.data.error,
            tfaStatus: respData.data.tfaStatus
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const forgotPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminWalletApi/forgotPassword`,
            // headers: {
            //     'Authorization': localStorage.admin_token
            // },
            data

        });

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const changePassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminWalletApi/resetPassword`,
            // headers: {
            //     'Authorization': localStorage.admin_token
            // },
            data

        });

        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getAllPairs = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getPairs`,
            headers: {
                'Authorization': localStorage.admin_wallet_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

// Update Currency Id 
// export const updateCurrency = async (data,headers) => {
//     console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
//     try {
//         let bodyFormData = new FormData();
//         bodyFormData.append('currencyName', data.currencyName);
//         bodyFormData.append('currencySymbol', data.currencySymbol);
//         bodyFormData.append('currencyType', data.currencyType);
//         bodyFormData.append('photo', data.photo);
//         bodyFormData.append('decimals', data.decimals);
//         bodyFormData.append('fee', data.fee);
//         bodyFormData.append('minimum', data.minimum);
//         bodyFormData.append('contractAddress',data.contractAddress);
//         bodyFormData.append('userId', data.userId);
//         var headersDefault = {
//             'Content-Type': 'multipart/form-data',
//             'Authorization': localStorage.
//         };
//         var finalHeaders = Object.assign(headersDefault, headers);

//         console.log(bodyFormData,"checking body formdata");
//         console.log(finalHeaders,"finalHeaders");

//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/adminapi/updateCurrency`,
//             headers: finalHeaders,
//             data: bodyFormData
//         });

//     /* Merge defaults and options, without modifying defaults */
//         console.log(respData,'checking ResponseData');
//         return {
//             loading: true,
//             result: respData.data.result
//         }

//     }
//     catch (err) {
//         return {
//             loading: false,
//             error: err
//         }
//     }
// }

export const addFaq = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/addFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.result
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateFaq = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/updateFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.result
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getAllUsers = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getAllUsers`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result,
            count: respData.data.count

        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
//Get Currency id currency management module

export const getCurrencyId = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getCurrencyId?userid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getFaqId = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getFaq/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getPairId = async (data) => {
    try {
        console.log('data', data);
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getPairId?pairid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getUser = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getUsers?userid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result,
            count:respData.data.count
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}



export const updatePassword = async (data) => {
    try {
        console.log("...adminwallet",localStorage.admin_token)
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminWalletApi/changePassword`,
           
            data
        });
        return {
            loading: false,
            message: respData.data.message,

        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const userBlance = async (data) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/userBlance`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params:data
        });
        console.log('result of Data update Profile', respData);
        return {
            status: "success",
            result:respData.data.result
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const addUser = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/addUser`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data update Profile', respData);
        return {
          
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const updateUser = async (data) => {
    try {

        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/updateUser`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
        }
    }
    catch (err) {

        console.log(err.response.data,"errrrrrrrrrrrrrrrrrrrrrrrrrr")
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const deleteUser = async (data) => {
    try {

        let respData = await axios({
            'method': 'delete',
            'url': `${config.API_URL}/adminapi/deleteUser`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });

        return {
            message:respData.data.message
        }
    }
    catch (err) {

        console.log(err.response.data,"errrrrrrrrrrrrrrrrrrrrrrrrrr")
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const userTfaDisable = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/userTfaDisable`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });

        return {
            status: respData.data.status,
            message:respData.data.message
        }
    }
    catch (err) {
        return {
            status: err.response.data.status,
            message:err.response.data.message
        }
    }
}
export const blockUser = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/blockUser`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });

        return {
            message:respData.data.message
        }
    }
    catch (err) {

        console.log(err.response.data,"errrrrrrrrrrrrrrrrrrrrrrrrrr")
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const addCms = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/addCms`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            success:respData.data.success,
            result:respData.data.result
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getCmsList = async (data) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getCmsList`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           params: data
        });

        return {
           
            success:respData.data.success,
            result:respData.data.result
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getSingleCmsDetails = async (id) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getSingleCmsDetails/`+id,
            headers: {
                'Authorization': localStorage.admin_token
            },
            
        });

        return {
           
            success:respData.data.success,
            result:respData.data.result
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateCms = async (data) => {
    try {

        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/updateCms`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            success:respData.data.success,
            result:respData.data.result
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const deleteCms = async (id) => {
    try {

        let respData = await axios({
            'method': 'delete',
            'url': `${config.API_URL}/adminapi/deleteCms/`+id,
            headers: {
                'Authorization': localStorage.admin_token
            },
            
        });

        return {
           
            success:respData.data.success,
            result:respData.data.result
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const updateEmail = async (data) => {
    try {

        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/updateEmailTemplate`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data update Profile', respData);
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const getLoginHistory = async (data) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getLoginHistory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: true,
            result: respData.data.result,
            count:respData.data.count
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getSupportNotification = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getSupportNotification`,
            'headers': {
                'Authorization': localStorage.admin_token
            }
        });
        return {
            status: respData.data.status,
            count: respData.data.count,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getNotification = async (param) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getNotification`,
            'params':param,
            'headers': {
                'Authorization': localStorage.admin_token
            }
        });
        return {
            status: respData.data.status,
            count: respData.data.count,
            result: respData.data.result,
            message:respData.data.message
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getAllNotification = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getAllNotification`,
            'headers': {
                'Authorization': localStorage.admin_token
            }
        });
        return {
            status: respData.data.status,
            count: respData.data.count,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const update_notification_status= async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/update_notification_status`,
            data,
            'headers': {
                'Authorization': localStorage.admin_token
            }
        });
        return {
            status: respData.data.status,
           
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getFaqList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getFaqList`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result,
            count:respData.data.count
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}






export const getAddressProof = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/proof/getAllAddress`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data

        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getEmailid = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getEmailId?cmsId=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getBankDetails = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/proof/getAllBank`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);


        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const UpdateStatus = async (data) => {
    try {
        console.log('result of Data---', data);
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/proof/updateStatus`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
           
        });
        console.log('result of Data', respData);


        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getadminUsers = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/profile`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const get2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/get2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const update2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/update2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmm', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result,
            message:respData.data.messages,
            success:respData.data.success
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const disable2faCode = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/diabled2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmm', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result,
            message:respData.data.messages,
            success:respData.data.success

        }

    }
    catch (err) {

        // console.log("ERRRRRRRRRrrrrrrrrrrr")
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const deleteFaq = async (id) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/deleteFaq/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });
    
      // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            message: respData.data.message
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}



//Support page 
export const getSupportList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/supportList`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

//Using post method only Support page Pass id using delete api  

export const deleteSupport = async (id) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/supportDelete/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });
        console.log('result of Data', respData);
        console.log(id, '------------checkData');

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getSupportId = async (id, dispatch) => {
    console.log('id', id);

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getSupportId/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData.data.userValue
        }
    }
    catch (err) {
        console.log(err, 'err');
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const closeticket = async (id, dispatch) => {
    //console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API}/adminapi/closeticket/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData.data.userValue
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const savereply = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API}/adminapi/savereply`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });

        // console.log(respData.data.result,"hsdjfhjksdhfjkdhfjhdsjkghdfjkghjkdfhgjkdfhgjkdfhgjkdhfgjkhdfk");

        return {
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const logout = (history) => {

    localStorage.removeItem('admin_wallet_token');
    toastAlert('success',"Logout Successfully",'logout')
    history.push('/Login')
    setAuthToken('')
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const getSetting = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/setting`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateSetting = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/setting`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            status: 'success',
            loading: false,
            messages: respData.data.messages,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}

