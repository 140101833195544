import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import {
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Visibility as VisibilityIcon } from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

import { getWalletTransfer } from "../../actions/admin";

//import modal
import TransferDetailsModal from "./transferDetailsModal";
import WithdrawModal from "./withdrawModal";
// import lib
import { momentFormat } from "../../lib/dateTimeHelper";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

const initialFormValue = {
  reason: "",
};
let currentUser = {};
const IdProof = (props) => {
  const classes = useStyles();

  // state
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [withdrawModal, setwithdrawModal] = useState(false);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params: CellParams) => {
        return <p>{momentFormat(params.row.createdAt)}</p>;
      },
    },
    {
      field: "currencySymbol",
      headerName: "Currency Symbol",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "View Transfer Details",
      headerName: "View Transfer Details",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params: CellParams) => {
        return (
          <>
            {
              <a onClick={() => handleModal(params.row)}>
                {" "}
                <VisibilityIcon />
              </a>
            }
          </>
        );
      },
    },
  ];

  const handleModal = (data) => {
    setOpenModal(!openModal);
    if (!openModal) {
      setModalData(data);
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
    let reqQuery = {
      page: page + 1,
      limit: limit,
      search: e.target.value,
    };
    fetchList(reqQuery);
  };

  // function
  const fetchList = async (reqData) => {
    try {
      setLoading(true);
      const { status, loading, result, count } = await getWalletTransfer(
        reqData
      );

      setLoading(loading);
      if (status == "success") {
        setRowCount(count);
        let respData = result.map((item, key) => {
          return {
            id: key,
            ...item,
          };
        });
        setData(respData);
      } else {
      }
    } catch (err) {}
  };

  const handlePagination = (params) => {
    let reqData = {
      page: params.page + 1,
      limit: limit,
      search: search,
    };
    fetchList(reqData);
    setPage(params.page);
  };
  useEffect(() => {
    var reqData = {
      limit: limit,
      page: page + 1,
      search: "",
    };
    fetchList(reqData);
  }, []);

  return (
    <div className={classes.root}>
      <TransferDetailsModal
        handleModal={handleModal}
        openModal={openModal}
        data={modalData}
      />

      <Paper className={classes.paper}>
        <Card>
          <Dialog open={modalOpen} aria-labelledby="form-dialog-title">
            <DialogContent></DialogContent>
          </Dialog>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Admin Wallet List</h4>
            </div>
          </CardHeader>
          <CardBody>
            <div className="topFilter">
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={handleChange}
                  className="tableSearch"
                />
                <div className="input-group-append">
                  <Tooltip title="Search by Currency" placement="right">
                    <span>
                      <InfoIcon />
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={data}
                columns={columns}
                rowCount={rowCount}
                page={page}
                onPageChange={handlePagination}
                paginationMode={"server"}
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
};

export default IdProof;
