import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";

//import action
import { changeWalletAddress } from "../../actions/admin";
//import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const intialFormvalue = {
  privateKey: "",
  toAddress: "",
  otpTextBox: false,
  otp: "",
  password: "",
};

const WithdrawCoinModal = (props) => {
  const classes = useStyles();

  const { handleModal, openModal, data, handleClose } = props;

  // state
  const [modalStyle] = useState(getModalStyle);
  const [formVlaue, setFormValue] = useState(intialFormvalue);
  const [validate, setValidate] = useState({});
  const [lodaer, setlodaer] = useState(false);
  const { privateKey, toAddress, otpTextBox, otp, password } = formVlaue;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formVlaue, ...{ [name]: value } };
    setValidate({});
    setlodaer(false);
    setFormValue(formData);
  };

  const handleSubmit = async (e) => {
    try {
      let reqData = {
        toAddress,
        privateKey: "0x" + privateKey,
        password,
        currencyId: data && data.currencyId && data.currencyId._id,
        currencySymbol:
          data && data.currencyId && data.currencyId.currencySymbol,
        otpTextBox,
        otp,
      };
      setlodaer(true);
      const { status, loading, message, error } = await changeWalletAddress(
        reqData
      );
      if (status == true) {

        setlodaer(false);
        setFormValue(intialFormvalue);
        toastAlert("success", message, "changeWalletAddress");
        setValidate({});
        handleModal();
      } else if (status == "OTP") {
        setlodaer(false);
        let formData = { ...formVlaue, ...{ otpTextBox: true } };
        setFormValue(formData);
        setValidate({});
        toastAlert("success", message, "changeWalletAddress");
      } else {
        console.log("...errors", error);
        if (!isEmpty(error)) {
          setValidate(error);
          return;
        }
        toastAlert("error", message, "changeWalletAddress");
      }
    } catch (err) {
      console.log("...errr", err);
    }
  };

  return (
    <div>
      <Modal
        className="primary_modal"
        open={openModal}
        onClose={handleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper + " primary_modal_body"}>
          <h2>Change Wallet Address</h2>
          <TableBody>
            <TableRow>
              <TableCell align="left">{"Wallet Address :"}</TableCell>
              <TableCell align="left">
                {" "}
                <input
                  type="text"
                  value={toAddress}
                  name={"toAddress"}
                  onChange={handleChange}
                  className="tableSearch"
                />
                {validate.toAddress && (
                  <p className="error-message">{validate.toAddress}</p>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">{"PrivateKey :"}</TableCell>
              <TableCell align="left">
                {" "}
                <input
                  type="text"
                  name={"privateKey"}
                  value={privateKey}
                  onChange={handleChange}
                  className="tableSearch"
                  disabled={data.currencySymbol == "BDX" ? true : false}
                />
                {validate.privateKey && (
                  <p className="error-message">{validate.privateKey}</p>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">{"Password :"}</TableCell>
              <TableCell align="left">
                {" "}
                <input
                  type="password"
                  name={"password"}
                  value={password}
                  onChange={handleChange}
                  className="tableSearch"
                />
                {validate.password && (
                  <p className="error-message">{validate.password}</p>
                )}
              </TableCell>
            </TableRow>
            {otpTextBox && (
              <TableRow>
                <TableCell align="left">{"Otp :"}</TableCell>
                <TableCell align="left">
                  {" "}
                  <input
                    type="text"
                    name={"otp"}
                    value={otp}
                    onChange={handleChange}
                    className="tableSearch"
                  />
                  {validate.otp && (
                    <p className="error-message">{validate.otp}</p>
                  )}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell align="left">{"Currency :"}</TableCell>
              <TableCell align="left">{data.currencySymbol}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell>
                <Button
                  className="mr-2"
                  variant="contained"
                  // disabled={lodaer}
                  onClick={() => {
                    // let formData={...formVlaue,...{privateKey:"",}}
                    setFormValue(intialFormvalue);
                    setValidate({});
                    handleModal();
                  }}
                >
                  Close
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  //   disabled={lodaer}
                  onClick={() => handleSubmit()}
                >
                  Update
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </div>
      </Modal>
    </div>
  );
};

// WithdrawModal.propTypes = {
//     data: PropTypes.shape({
//         email: PropTypes.string.isRequired,
//         coin: PropTypes.string.isRequired,
//         toaddress: PropTypes.string.isRequired,
//         privateKey: PropTypes.number.isRequired,
//         commissionFee: PropTypes.number.isRequired,
//     }),
// };

// WithdrawCoinModal.defaultProps = {
//     data: {
//         email: '',
//         currencySymbol: '',
//         toaddress: '',
//         privateKey: 0,
//         commissionFee: 0,
//     }
// };

export default WithdrawCoinModal;
