let key = {};
// if (process.env.NODE_ENV === "production") {
if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  // const API_URL = 'http://144.202.52.224:2053';

  //demo url
  const API_URL = "https://cppapi.bitsma.in";
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6LdGtRwiAAAAADikfn4gCT7y4Excv5grm5m6fan4", //local
    API_URL: `${API_URL}`,
    // FRONT_URL: 'http://104.207.141.185',
    // ADMIN_URL: 'http://104.207.141.185/bitsdealsadmin',

    // demo url
    FRONT_URL: "https://cpp.bitsma.in",
    ADMIN_URL: "https://cpp.bitsma.in",
    // TRADE_URL: 'https://java.deepliquidity.exchange/api/trade',
    getGeoInfo: "https://ipapi.co/json/",

    socialMedia: {
      facebook: {
        appId: "1034988646970193",
      },
      linkedIn: {
        clientId: "78szlpfkw7ee7s",
        redirectUrl: "https://99893158a13c.ngrok.io/signup",
        oauthUrl:
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
        scope: "r_liteprofile%20r_emailaddress",
        state: "123456",
      },
    },
  };
} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set Demo Config")
  const API_URL = 'https://anandspotapi.wealwin.com'; // DEMO
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6LdGtRwiAAAAADikfn4gCT7y4Excv5grm5m6fan4", //local
    API_URL: `${API_URL}`,
    
    // demo url
    FRONT_URL: "https://anandspotadmin.wealwin.com",
    ADMIN_URL: "https://anandspotadmin.wealwin.com",
    ADMIN_WALLET_URL:"https://anandspotadmin.wealwin.com",
    getGeoInfo: "https://ipapi.co/json/",

    socialMedia: {
      facebook: {
        appId: "1034988646970193",
      },
      linkedIn: {
        clientId: "78szlpfkw7ee7s",
        redirectUrl: "https://99893158a13c.ngrok.io/signup",
        oauthUrl:
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
        scope: "r_liteprofile%20r_emailaddress",
        state: "123456",
      },
    },
  };
}
else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6Lc-x6IhAAAAACnEERbCqD3U8vRmKddriMHO-Roo", 
    API_URL: `${API_URL}:2054`,
    FRONT_URL: "http://localhost:3002",
    ADMIN_URL: "http://localhost:3000",
    // TRADE_URL: 'http://54.211.230.83:8081/api/trade',

    getGeoInfo: "https://ipapi.co/json/",

    socialMedia: {
      facebook: {
        appId: "1034988646970193",
      },
      linkedIn: {
        clientId: "78szlpfkw7ee7s",
        redirectUrl: "https://99893158a13c.ngrok.io/signup",
        oauthUrl:
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
        scope: "r_liteprofile%20r_emailaddress",
        state: "123456",
      },
    },
  };
}

export default key;
