// import axios
import axios from 'axios';

// import lib
import config from '../lib/config';
import { getAuthToken } from '../lib/localStorage'

axios.defaults.headers.common['Authorization'] = getAuthToken();

export const getCoinWithdrawRequest = async (reqData) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/withdrawRequest/coin`,
            params:reqData
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            count: respData.data.count

        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const acceptCoinWithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/withdrawAccept/coin`,
            data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const acceptFiatWithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/withdraw/fiat`,
            data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const rejectFiatWithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/withdraw/fiat`,
            data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const getDepositRequest = async (data) => {
    try {
        let respData = await axios({
            'url': `${config.API_URL}/adminapi/depositRequest/crypto`,
            params:data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const approvedDepositRequest = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/depositRequest/fiat`,
            data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

// START OLD ACTION FUNCTION
// export const getWithdrawRequest = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `${config.API_URL}/adminapi/withdraw/fiat`,
//             data
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     } catch (err) {
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }
// END OLD ACTION FUNCTION

// START NEW ACTION FUNCTION --> crypto getRequest
export const getWithdrawRequest = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/withdrawRequest`,
            params:data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            count: respData.data.count

        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
// END NEW ACTION FUNCTION   --> Crypto Reject

export const rejectWithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/rejectWithdraw`,
            data
        });

       if(respData.status){
          return {
            status:respData.data.status,
            message:respData.data.message,
          }
       }else{
          return {
             status:respData.data.status,
             message:respData.data.message,
          }
       }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

//this woking function  for withdraw // Crypto Accept
export const acceptWithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/acceptWithdraw`,
            data
        });
        
       if(respData.status){
          return {
            status:respData.data.status,
            message:respData.data.message,
          }
       }else{
          return {
             status:respData.data.status,
             message:respData.data.message,
          }
       }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}



//..........................................Fiat Requetss........................................................//



export const getFiatDepositReq = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/deposit/fiat`,
            params:data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const rejectDeposit = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/deposit/fiat`,
            data
        });

        console.log("respDatarespData",respData.data)
      
          return {
            status:respData.data.status,
            message:respData.data.message,
          }
       
       
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const acceptDeposit = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/deposit/fiat`,
            data
        });

        console.log("respDatarespData",respData.data)
      
          return {
            status:respData.data.status,
            message:respData.data.message,
          }
       
       
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getFiatWithdrawReq = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/withdraw/fiat`,
           params: data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const fiatRejectWithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/withdraw/fiat`,
            data
        });

      
          return {
            status:respData.data.status,
            message:respData.data.messages,
          }
       
       
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const fiatAcceptWithdraw = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/withdraw/fiat`,
            data
        });
      
          return {
            status:respData.data.status,
            message:respData.data.messages,
          }
       
       
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const getTransferHistory = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getTransferHistory`,
            data
        });
        return {
            status: "success",
            loading: false,
            count:respData.data.result.count,
            result: respData.data.result.data
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}


//..............................................PRICE CONVERSION ..............................................................//

export const getPriceConversion = async (data) => {
    try {
      let respData = await axios({
        method: "get",
        url: `${config.API_URL}/adminapi/priceConversion`,
        params:data
      });
  
      return {
        status: "success",
        loading: false,
        result: respData.data.result,
      };
    } catch (err) {
  
      return {
        status: "failed",
        loading: false,
        error: err.response.data.errors,
      };
    }
  };


  export const updatePriceConversion = async (data) => {
    try {
      let respData = await axios({
        method: "put",
        url: `${config.API_URL}/adminapi/priceConversion`,
        data
      });
  
      return {
        status: "success",
        loading: false,
        message: respData.data.message,
      };
    } catch (err) {
  
      return {
        status: "failed",
        loading: false,
        error: err.response.data.errors,
      };
    }
  };