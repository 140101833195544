import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";

// import components
import "assets/css/material-dashboard-react.css?v=1.9.0";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoginHistory from "../deepliquidity_exchange_server/modals/loginHistory";

//import Admin Wallet List
import TransferDetails from "./pages/adminWallet/transferDetails";
import Admin from "./components/layouts/Admin";
import WalletList from "pages/adminWallet/walletList";
import ConditionRoute from "./components/Route/ConditionRoute";
import LoginPage from "./pages/LoginPage/Login";
import ForgotPage from "./pages/ForgotPage/ForgotPassword";
import changePassword from "./pages/ForgotPage/changePassword";
import EditProfile from './pages/UserProfile/EditProfile';
import store from "./store";

import globalStyle from "./style/global.style";

// import action
import { decodeJwt } from "./actions/jsonWebToken";
import FiatDepositRequest from "pages/Wallet/fiatDepoistReq";

const history = createBrowserHistory();

const App = () => {
  const { isAuth } = store.getState().currentUser;

  useEffect(() => {
    if (isAuth != true && localStorage.admin_wallet_token) {
      decodeJwt(localStorage.admin_wallet_token, store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={globalStyle}>
        <ToastContainer />
        <Router history={history} basename={"/"}>
          <Switch>
            {/* Auth Route */}
            <ConditionRoute path="/login" component={LoginPage} type={"auth"} />
            <ConditionRoute
              path="/forget"
              component={ForgotPage}
              type={"auth"}
            />
            <ConditionRoute
              path="/changepassword/:authToken"
              component={changePassword}
              type={"auth"}
            />

            {/* <ConditionRoute path="/changepassword" component={changePassword} type={"public"} /> */}

            {/* Auth Route */}

            {/* Private Route */}
            <ConditionRoute
              path="/admin-wallet-list"
              component={WalletList}
              layout={Admin}
              type={"private"}
              store={store}
            />
           <ConditionRoute path="/editprofile" component={EditProfile} layout={Admin} type={"private"} />
            <ConditionRoute
              path="/wallet-transfer-list"
              component={TransferDetails}
              layout={Admin}
              type={"private"}
              store={store}
            />

            <Redirect from="/" to="/login" />

            {/* <Route path="/admin" component={Admin} /> */}
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
