import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import { Paper, Button } from "@material-ui/core";
import { Visibility as VisibilityIcon } from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

// import component
import DepositRequestModal from "./fiatDepositModal";

// import action
import {
  getFiatDepositReq,
  approvedDepositRequest,
} from "./../../actions/wallet";

// import lib
import { transactionStatus } from "../../lib/statusCode";
import { toastAlert } from "../../lib/toastAlert";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

const FiatDepositRequest = (props) => {
  const classes = useStyles();

  // state
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [modalData, setModalData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);

  const columns = [
    { field: "email", headerName: "Email", width: 200 },
    { field: "currencySymbol", headerName: "Currency Symbol", width: 200 },
    { field: "userAssetId", headerName: "User Id", width: 200 },
    { field: "amount", headerName: "Amount", width: 150 },
    { field: "createdAt", headerName: "Date", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params: CellParams) => {
        return (
          <>
            {params.row.status == 2 ? (
              <a onClick={() => handleModal(params.row)}>
                {" "}
                <VisibilityIcon />
              </a>
            ) : (
              <a> {params.row.sts}</a>
            )}

            {/* {
                            params.row.status == 2 &&
                            <a onClick={() => handleModal(params.row)}>  <VisibilityIcon /></a>

                        }
                        {
                            params.row.status == 4 &&
                            <a > Rejected</a>

                        }
                        {
                            params.row.status == 3 &&
                            <a >  Accepted</a>

                        } */}
          </>
        );
      },
    },
  ];
  let reqData = {
    limit: limit,
    page: page + 1,
    search: search,
  };
  // function
  const fetchDepositRequest = async (reqData) => {
    try {
      const { status, loading, error, result } = await getFiatDepositReq(reqData);
      if (status == "success") {
        let respData = result.data.map((item, key) => {
          return {
            id: key,
            ...item,
          };
        });
        setCount(result.count);
        setData(respData);
      } else {
      }
    } catch (err) {}
  };

  const handleApproved = async (transactionId) => {
    try {
      let reqData = {
        transactionId,
      };
      const { status, loading, error, result } = await approvedDepositRequest(
        reqData
      );
      if (status == "success") {
        fetchDepositRequest();
        toastAlert("success", result.messages, "depositRequest");
      } else {
        toastAlert("error", error.messages, "depositRequest");
      }
    } catch (err) {}
  };

 
  const handleModal = (data) => {
    setOpenModal(!openModal);
    if (!openModal) {
      setModalData(data);
    }
  };
  const handleSerach = (e) => {
    setSearch(e.target.value);
    let reqQuery = {
      page: page + 1,
      limit: limit,
      search: e.target.value,
    };
    fetchDepositRequest(reqQuery);
  };
  const handlePagination = (params) => {
    let reqData = {
      page: params.page + 1,
      limit: limit,
      search: search,
    };
    fetchDepositRequest(reqData);
    setPage(params.page);
  };
  useEffect(() => {
    fetchDepositRequest(reqData);
  }, []);

  return (
    <div className={classes.root}>
      <DepositRequestModal
        handleModal={handleModal}
        openModal={openModal}
        data={modalData}
        fetchDepositRequest={fetchDepositRequest}
      />

      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>
                Fiat Deposit Request List
              </h4>
            </div>
          </CardHeader>
          <CardBody>
          <div className="topFilter">
              <div className="input-group">
                <input
                  type="text"
                  placeholder="search"
                  onChange={handleSerach}
                  className="tableSearch"
                />
                <div className="input-group-append">
                  <Tooltip title="search by Email, Buy/Sell, OrderType, PairNames " placement="right">
                    <span>
                      <InfoIcon />
                    </span>
                  </Tooltip>
                </div>
              </div>
            
            </div>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={data}
                columns={columns}
                page={page}
                rowCount={count}
                onPageChange={handlePagination}
                paginationMode={"server"}
                pagination
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
};

export default FiatDepositRequest;
