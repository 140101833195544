import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TableContainer,
  Table,
} from "@material-ui/core";
import { momentFormat } from "../../lib/dateTimeHelper";
import { CopyToClipboard } from "react-copy-to-clipboard";

//import lib
import { toastAlert } from "../../lib/toastAlert";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PassbookModal = (props) => {
  const classes = useStyles();

  const { handleModal, openModal, data } = props;

  // state
  const [modalStyle] = useState(getModalStyle);

  return (
    <div>
      <Modal
        className="primary_modal"
        open={openModal}
        onClose={handleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper + " primary_modal_body"}>
          <h2>Account Details</h2>
          <TableContainer className="mt-3">
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell align="left" className="firstColumn">
                    {"Date :"}
                  </TableCell>
                  <TableCell align="left">
                    {momentFormat(data.createdAt)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className="firstColumn">
                    {"Currency :"}
                  </TableCell>
                  <TableCell align="left">{data.currencySymbol}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className="firstColumn">
                    {"To Address :"}
                  </TableCell>

                  <TableCell align="left">
                    <div className="flexAddress">
                      <span className="addressWallet"> {data.toaddress}</span>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className="firstColumn">
                    {"Amount :"}
                  </TableCell>
                  <TableCell align="left">{data.amount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className="firstColumn">
                    {"Transaction Reference :"}
                  </TableCell>

                  {data.viewExpolerLink != "" &&
                  data.viewExpolerLink != undefined ? (
                    <TableCell align="left">
                      <a href={`${data.viewExpolerLink}`} target="_blank">
                        <span className="addressWallet"> {data.txid}</span>
                      </a>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell align="left">
                        <div className="flexAddress">
                          <span className="addressWallet"> {data.txid}</span>

                          <CopyToClipboard
                            text={data.address}
                            onCopy={() => {
                              toastAlert("success", "Copied!", "copied");
                            }}
                          >
                            <a className="btn btnType1 py-0 my-0 px-2">
                              <i class="fa fa-copy"></i>
                            </a>
                          </CopyToClipboard>
                        </div>
                      </TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell align="left" className="firstColumn">
                    {"Status :"}
                  </TableCell>
                  <TableCell align="left">{data.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className="firstColumn"></TableCell>
                  <TableCell>
                    <Button
                      className="mr-2"
                      variant="contained"
                      onClick={() => handleModal()}
                    >
                      Close
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
};

// WithdrawModal.propTypes = {
//     data: PropTypes.shape({
//         email: PropTypes.string.isRequired,
//         coin: PropTypes.string.isRequired,
//         toaddress: PropTypes.string.isRequired,
//         amount: PropTypes.number.isRequired,
//         commissionFee: PropTypes.number.isRequired,
//     }),
// };

// PassbookModal.defaultProps = {
//     data: {
//         email: '',
//         currencySymbol: '',
//         toaddress: '',
//         amount: 0,
//         commissionFee: 0,
//     }
// };

export default PassbookModal;
